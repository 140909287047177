<template>
  <td v-tooltip="lang.attentionRequests.navbar.takeovers[languageSelected]">
    <i
      class="material-icons pt-2 cursor-pointer text-white"
      style="width:20px !important"
      @click="getChatData"
    >
      history
    </i>

    <!-- takeovers -->
    <vs-popup
      classContent="popup-example"
      :title="lang.contextVariables.chat.userDataPopup.title[languageSelected]"
      :active.sync="userDataPopup"
    >
      <UserData
        v-if="userData && userData.takeovers && userData.takeovers.length"
        :takeovers="userData.takeovers"
      />
      <span v-else>
        {{
          lang.contextVariables.chat.userDataPopup.emptyTakeovers[
            languageSelected
          ]
        }}
      </span>
    </vs-popup>
  </td>
</template>

<script>
import { mapState } from 'vuex'
import konecta from '@/services/konecta'

export default {
  name: 'Takeovers',
  data() {
    return {
      userDataPopup: false,
      userData: null
    }
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    UserData: () => import('../../../components/UserData.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    getChatData() {
      this.$vs.loading()
      konecta
        .get(
          `/chat/${this.userId}?select[context.data]=true&select[takeovers]=true&populate[path]=takeovers.user&populate[select]=name`
        )
        .then(response => {
          this.userData = response.data
          this.userDataPopup = true
        })
        .catch(error => {
          this.$log.error(error)
          this.$vs.notify({
            title: this.lang.contextVariables.error.title[
              this.languageSelected
            ],
            text: this.lang.contextVariables.error.text[this.languageSelected],
            color: 'danger',
            position: 'top-right'
          })
        })
        .finally(() => this.$vs.loading.close())
    }
  }
}
</script>
